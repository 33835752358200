body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: rgb(28, 28, 52);
}

.App {
  text-align: center;
}

.start_btn {
  border-radius: 30px;
  background-color: rgb(238, 95, 6);
  border: none;
  width: 160px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5%;
  cursor: pointer;
}

.start_btn:hover {
  background-color: #fb0a0a;
}
.next_btn {
  border-radius: 30px;
  background: linear-gradient(137.42deg, #ffb800 0%, #fd5a06 104.6%);
  border: none;
  width: 160px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5%;
  cursor: pointer;
  overflow: hidden;
  min-width: 130px;
}

.next_btn:hover {
  background: orange;
}

.prev_btn {
  border-radius: 30px;
  background: white;
  border: none;
  width: 160px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5%;
  cursor: pointer;
  opacity: 0.7;
}

.prev_btn:hover {
  opacity: 1;
}

a {
  text-decoration: none;
}

.round {
  position: relative;
  top: 6px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.round label {
  background-color: #282e46;
  border: 1px solid rgb(238, 95, 6);
  border-radius: 50%;
  box-shadow: 0 3px 8px black inset;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 3px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="radio"] {
  visibility: hidden;
}

.round input[type="radio"]:checked + label {
  background-color: rgb(238, 95, 6);
  border-color: rgb(238, 95, 6);
  box-shadow: none;
}

.round input[type="radio"]:checked + label:after {
  opacity: 1;
}

.modal_window {
  position: fixed;
  z-index: 100;
  background-color: rgba(28, 28, 52, 0.8);
  width: 100vw;
  height: 100vh;
}

.profile-img {
  width: 100px;
  height: 80px;
}

.img-upload-btn {
  border: 1px solid orange;
  padding: 10px 20px;
  color: white;
  background-color: #30354c;
  border-radius: 30px;
  cursor: pointer;
}

.img-upload-btn:hover {
  opacity: 0.8;
}

.slideBar {
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  display: flex;
  justify-content: right;
  z-index: 90;
}
.infoText {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  display: flex;
  justify-content: right;
  z-index: 90;
}

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 24px;
  margin-top: 3px;
  margin-right: 3px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider_empty {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #242a41;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0 4px black inset;
  color: #ffb800;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #242a41;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0 4px black inset;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #3b435f;
  box-shadow: 0 0 2px white inset;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #242a41;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #ffb800;
}

.slider.round {
  border-radius: 34px;
}

.slider_empty.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider_check {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #242a41;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0 4px black inset;
}

.slider_check:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #ffb800;
  box-shadow: 0 0 2px white inset;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider_check {
  background-color: #242a41;
}

input:checked + .slider_check:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #ffb800;
}

.slider_check.round {
  border-radius: 34px;
}

.slider_empty_check.round {
  border-radius: 34px;
}

.slider_check.round:before {
  border-radius: 50%;
}

.slider_wrap {
  background-color: #3b435f;
  border: 1px solid #ffb800;
  border-radius: 50px;
  min-width: 110px;
  max-width: 210px;
  height: 30px;
  display: inline-flex;
  justify-content: space-between;
  overflow: hidden;
  margin: 4px;
}

.slider_text {
  color: white;
  padding-right: 4px;
}

.plus_minus {
  display: inline-block;
  position: relative;
  margin: 0 4px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.plus_minus:hover {
  color: white;
}

.plus_minus:active {
  font-size: 18px;
}

.sideBar {
  background-color: rgb(28, 28, 52);
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 5;
}

.sideBarNavWrap {
  background: linear-gradient(
    135.75deg,
    rgba(255, 255, 255, 0.1) -1.35%,
    rgba(30, 36, 62, 0.1) 100%
  );
  height: 100vh;
  border-radius: 0 20px 0 0;
}

.sideNav {
  padding: 20px 0 0 20px;
  text-align: left;
}

.sideNav span {
  color: #8d8d8d;
  display: inline-block;
  height: 30px;
  margin-left: 10px;
}

.sideNav span.active {
  color: #ffb800;
}

.sideBarLi {
  display: flex;
  align-items: flex-end;
}

.side_nav_divider {
  height: 15px;
  width: 2px;
  border-left: 2px solid #8d8d8d;
  margin-left: 17px;
}

.side_nav_divider.active {
  border-left: 2px solid #fb0a0a;
}

.side_nav_circle {
  text-align: center;
  height: 30px;
  width: 30px;
  border: 2px solid #8d8d8d;
  border-radius: 100%;
  display: inline-block;
  overflow: hidden;
}

.side_nav_circle.active {
  border: 2px solid #fb0a0a;
}

.side_nav_circle.active svg * {
  fill: #fb0a0a;
}

.cold_spot_wrap {
  display: flex;
  justify-content: center;
}

.cold_spot_wrap svg {
  margin: 10px;
}

.cold_spot_wrap svg *:hover {
  fill: white;
}

.cold_spot_wrap svg.active * {
  fill: #fb0a0a;
}

.erase {
  color: white;
  cursor: pointer;
  background-color: rgba(200, 0, 0, 0.5);
  display: inline-block;
  border-radius: 20px;
  padding: 0 10px;
  max-width: 200px;
  height: 34px;
  margin-top: 2px;
  overflow: hidden;
}

.erase:active {
  background-color: rgba(255, 0, 0, 0.7);
}

.areaLabel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100vw;
}

.areaLabel span {
  color: white;
  font-size: 18px;
  font-weight: bold;
}
.areaLabel span.ht_label1 {
  color: rgb(93, 93, 93);
  font-size: 11px;
  font-weight: bold;
}
.areaLabel span.ht_label2 {
  color: #fb0a0a;
  font-size: 18px;
  font-weight: bold;
}

.dragDelBlock span {
  color: rgb(93, 93, 93);
  font-weight: bold;
  vertical-align: super;
  color: red;
}

.dragDelBlock:hover span,
.dragDelBlock:hover svg * {
  color: red;
  fill: red;
}

select {
  background-color: rgb(28, 28, 52);
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  flex: 1;
  padding: 0 1em;
  color: #fff;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.select {
  position: relative;
  display: flex;
  width: 90%;
  height: 35px;
  border-radius: 20px;
  overflow: hidden;
}

.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px;
  background-color: rgb(28, 28, 52);
  transition: 0.25s all ease;
  pointer-events: none;
}

.select:hover::after {
  color: #f39c12;
}

.selectWrap .langSelect {
  border: none;
}

.selectWrap .langSelect fieldset {
  border: none;
}
.selectWrap .langSelect svg {
  color: #f39c12;
}

#lang-1 ul {
  background: transparent !important;
  background-color: transparent !important;
  color: #f39c12;
  box-shadow: none !important;
}
#lang-1 ul li.Mui-selected {
  font-weight: bold;
  color: white;
}

#lang-1 .MuiPopover-paper {
  background: transparent !important;
  background-color: transparent !important;
  color: #f39c12;
  box-shadow: none !important;
}

#container canvas {
  cursor: pointer;
}

.loadingImg {
  width: 40px;
  height: 40px;
  margin: auto;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loadingImgWrap {
  width: 220px;
  height: 220px;
  margin: auto;
  position: absolute;
  inset: 0 0 0 0;
  overflow: hidden;
}

.loadingImgWrap span {
  color: #909090;
}

.specUl {
  list-style: none;
}

.crossBtn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: -53px 0 23px 405px;
}

.crossBtn:hover path {
  fill: white;
}

.spec_box {
  background: linear-gradient(
    135.75deg,
    rgba(255, 255, 255, 0.1) -1.35%,
    rgba(30, 36, 62, 0.1) 100%
  );
}

.add_button_plus {
  background: linear-gradient(137.42deg, #ffb800 0%, #fc0a0a 74.64%);
  border: none;
  padding: 4px;
  font-size: 22px;
  border-radius: 20px;
  font-weight: bold;
  color: #2d2d2d;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.add_button_plus:active {
  background: #fb0a0a;
}

.list_bullet {
  color: white !important;
  cursor: pointer;
  font-weight: bold;
  border-radius: 100%;
  padding: 4px;
  background: #2d2f46;
  width: 20px !important;
  height: 20px !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}

.list_bullet.active {
  background: linear-gradient(137.42deg, #ffb800 0%, #fc0a0a 74.64%) !important;
  color: #2d2f46 !important;
}

.refreshBtn {
  cursor: pointer;
  background: none;
  border: none;
  padding: 3px 0px;
  position: relative;
  top: 8px;
  border-radius: 50%;
}

.refreshBtn:hover {
  background-color: gray;
}

.refreshBtn:active {
  background-color: black;
}
